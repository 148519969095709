const journals = ref([
  { value: "Factu", key: "Invoices" },
  { value: "Ncred", key: "Credit notes" },
  { value: "PDown", key: "Pricedowns" },
  { value: "Comm", key: "Orders" },
  { value: "Blivr", key: "Shipping notes" },
  { value: "Plsng", key: "Pulsings" },
  { value: "Ncsav", key: "RMA Credit note" },
  { value: "Ncorr", key: "Price correction credit note" },
]);
const privateProductsMenus = ref({
  replenished: true,
});

const journalValues = ref([
  "Factu",
  "Ncred",
  "PDown",
  "Comm",
  "Blivr",
  "Plsng",
  "Ncsav",
  "Ncorr",
]);
const productsMenu = ref([
  {
    key: "hot_deals",
    path: "/products/search",
    query: {
      attribute: { price_promo: { "price promo": true } },
      sort: ["STOCK_DESC"],
    },
    img: "/menu/hotdeals.png",
  },
  {
    key: "announcements",
    path: "/products/search",
    query: {
      attribute: { status_preorder: { "status preorder": true } },
      sort: [{ Created: { order: "desc" } }, { Updated: { order: "desc" } }],
    },
    img: "/menu/announcements.png",
  },
  {
    key: "arriving_soon", //new arrival
    path: "/products/search",
    query: {
      attribute: {
        // status_preorder: { "status preorder": true },
        in_stock: { "in stock": true },
      },
      sort: [{ "Values.first_release_date.Data": { order: "desc" } }],
    },
    img: "/menu/arrivingsoon.png",
  },
  {
    key: "in_stock",
    path: "/products/search",
    query: { attribute: { in_stock: { "in stock": true } }, sort: [] },
    img: "/menu/stock.png",
  },
  {
    key: "last_unit",
    path: "/products/search",
    query: {
      stock_range: {
        lte: 29,
        gte: 1,
      },
      sort: [],
    },
    img: "/menu/lastunits.png",
  },
  {
    key: "replenished",
    path: "/products/search",
    query: { attribute: { stock: { replenished: true } }, sort: [] },
    img: "/menu/replenished.png",
  },
]);
export { journals, journalValues, productsMenu, privateProductsMenus };
